// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centerContent {
  text-align: center;
  margin: auto;
  display: block;
  margin: 0 auto;
  padding: 1.5rem;
}

.tw-main-div {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: auto;
  padding: 1.5rem;
}

.twitter-component {
  width: auto;
  padding: 1.5rem;
}

.timeline-Header-title {
  font-size: 56px;
  font-weight: 100 !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/twitter-embed/twitter.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,cAAA;EACA,eAAA;AACF;;AACA;EACE,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AAEF;;AAAA;EACE,WAAA;EACA,eAAA;AAGF;;AAAA;EACE,eAAA;EACA,2BAAA;AAGF","sourcesContent":[".centerContent {\n  text-align: center;\n  margin: auto;\n  display: block;\n  margin: 0 auto;\n  padding: 1.5rem;\n}\n.tw-main-div {\n  margin-left: 15px;\n  margin-right: 15px;\n  margin-bottom: 15px;\n  width: auto;\n  padding: 1.5rem;\n}\n.twitter-component {\n  width: auto;\n  padding: 1.5rem;\n}\n\n.timeline-Header-title {\n  font-size: 56px;\n  font-weight: 100 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
