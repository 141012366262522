// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;
}

.experience-heading {
  font-size: 56px;
  font-weight: 400;
}

.experience-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.experience-container > * {
  flex: 1 1;
}

/* Media Query */
@media (max-width: 1380px) {
  .experience-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .experience-heading {
    font-size: 30px;
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/workExperience/WorkExperience.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2DAAA;EACA,mBAAA;EAAA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA,gBAAA;AAEA;EACE;IACE,eAAA;EAAF;AACF;AAGA;EACE;IACE,eAAA;IACA,kBAAA;EADF;AACF","sourcesContent":[".experience-cards-div {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));\n  gap: 1rem 1rem;\n}\n\n.experience-heading {\n  font-size: 56px;\n  font-weight: 400;\n}\n\n.experience-container {\n  display: flex;\n  width: 90%;\n  padding: 20px 10px;\n  margin: 0px auto;\n  margin-top: 2rem;\n}\n\n.experience-container > * {\n  flex: 1;\n}\n\n/* Media Query */\n\n@media (max-width: 1380px) {\n  .experience-heading {\n    font-size: 40px;\n  }\n}\n\n@media (max-width: 768px) {\n  .experience-heading {\n    font-size: 30px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
