// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-title {
  font-size: 52px;
  font-weight: 400;
  line-height: normal;
}

.startup-project-text img {
  max-width: 100%;
  height: auto;
}

.repo-cards-div-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;
}

/* Media Query */
@media (max-width: 1380px) {
  .project-title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .project-title {
    font-size: 30px;
    text-align: center;
  }
  .repo-cards-div-main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/projects/Project.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,2DAAA;EACA,mBAAA;EAAA,cAAA;AACF;;AAEA,gBAAA;AACA;EACE;IACE,eAAA;EACF;AACF;AACA;EACE;IACE,eAAA;IACA,kBAAA;EACF;EACA;IACE,2DAAA;EACF;AACF","sourcesContent":[".project-title {\n  font-size: 52px;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.startup-project-text img {\n  max-width: 100%;\n  height: auto;\n}\n\n.repo-cards-div-main {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));\n  gap: 1rem 1rem;\n}\n\n/* Media Query */\n@media (max-width: 1380px) {\n  .project-title {\n    font-size: 40px;\n  }\n}\n@media (max-width: 768px) {\n  .project-title {\n    font-size: 30px;\n    text-align: center;\n  }\n  .repo-cards-div-main {\n    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
