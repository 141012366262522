// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Agustina.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,mBAAmB;EACnB;0DACoD;EACpD,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB;0DAC6D;EAC7D,kBAAkB;AACpB;AACA;EACE;aACW;AACb;AACA;;EAEE,uBAAuB;AACzB;AACA;EACE,SAAS;EACT,sDAAsD;EACtD,mCAAmC;EACnC,kCAAkC;AACpC;AACA,gBAAgB;AAChB;EACE;;IAEE,cAAc;IACd,mBAAmB;EACrB;AACF","sourcesContent":["@font-face {\n  font-family: \"Agustina Regular\";\n  font-style: normal;\n  font-weight: normal;\n  src: local(\"Agustina Regular\"),\n    url(\"./assets/fonts/Agustina.woff\") format(\"woff\");\n  font-display: swap;\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: local(\"Montserrat\"),\n    url(\"./assets/fonts/Montserrat-Regular.ttf\") format(\"woff\");\n  font-display: swap;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\nhtml,\nbody {\n  scroll-behavior: smooth;\n}\nbody {\n  margin: 0;\n  font: 19px / 23px Montserrat, \"Montserrat\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n/* Media Query */\n@media (max-width: 1380px) {\n  html,\n  body {\n    font-size: rem;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
