// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.education-heading {
  font-size: 56px;
  font-weight: 400;
}

.education-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  margin: 0px auto;
  margin-top: 2rem;
}

.education-section > * {
  flex: 1 1;
  margin-bottom: 30px;
}

.education-card-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

@media (max-width: 1380px) {
  .education-card-container {
    padding-top: 0px;
  }
  .education-heading {
    font-size: 40px;
  }
  .education-text-subtitle {
    font-size: 18px;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .education-card-container {
    padding-top: 0px;
  }
  .education-heading {
    font-size: 30px;
    text-align: center;
  }
  .education-text-subtitle {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/containers/education/Education.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;EACA;IACE,eAAA;EACF;EACA;IACE,eAAA;IACA,kBAAA;EACF;AACF;AAEA;EACE;IACE,gBAAA;EAAF;EAEA;IACE,eAAA;IACA,kBAAA;EAAF;EAEA;IACE,eAAA;EAAF;AACF","sourcesContent":[".education-heading {\n  font-size: 56px;\n  font-weight: 400;\n}\n\n.education-section {\n  display: flex;\n  flex-direction: column;\n  width: 90%;\n  padding: 20px 0px;\n  margin: 0px auto;\n  margin-top: 2rem;\n}\n\n.education-section > * {\n  flex: 1;\n  margin-bottom: 30px;\n}\n\n.education-card-container {\n  display: flex;\n  flex-direction: column;\n  padding-top: 20px;\n}\n\n@media (max-width: 1380px) {\n  .education-card-container {\n    padding-top: 0px;\n  }\n  .education-heading {\n    font-size: 40px;\n  }\n  .education-text-subtitle {\n    font-size: 18px;\n    text-align: center;\n  }\n}\n\n@media (max-width: 768px) {\n  .education-card-container {\n    padding-top: 0px;\n  }\n  .education-heading {\n    font-size: 30px;\n    text-align: center;\n  }\n  .education-text-subtitle {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
